@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=PT+Serif:wght@700&family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
*{
    font-family: 'Montserrat', sans-serif;
    font-family: 'PT Serif', serif;
    font-family: 'Roboto', sans-serif;
}

/* ------------------------------------------------------------------------------------- */
/* About Us page 1 */
.text-justify{
    text-align: justify;    
}
.text-paragrap{
    font-size: 20px;
    font-family: 'montserrat';
    color:#1F5234;
}
h5.title{
    font-size: 50px;
    font-family: 'PT Serif';
    font-weight: bold;
    color: #1F5234;
}
.sub-title{
    font-size: 50px;
    font-family: 'montserrat';
    font-weight: normal;
    color: #1F5234;
}
h5.about-title span{
    font-size: 50px;
    font-family: 'PT Serif';
    font-weight: bold;
    color: #1F5234;
}
.text-dash span {
    display: inline-block;
    position: relative;  
}
.text-dash span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 4px solid #1F5234;
    top: 50%;
    width: 70%;
}
.text-dash span:after {
    left: 100%;
    margin-left: 15px;
}
p.about-content{
    font-size: 25px;
    font-family: 'Montserrat';
    margin-top: 3%;
}
.about-image {
    background-image: url("../images/about-img1.png");
    background-attachment: fixed;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    height: 55vh;
}
.green-bg{
    background-color: green;
    height: 55vh;
    opacity: 40%;
}
/* About Us page1 CSS End ----------------------------------------------------------------------------- */





/* mission Vission */
.mv-row-padding{
    padding: 8% 0% 8%;
}
.middle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.padding-right{
    padding-right: 4%;
}
.mv-title{
    font-size: 50px;
    font-family: 'PT Serif';
    font-weight: bold;
    color: #1F5234;
}
.border-left{
    border-left: 6px solid #24AA5A;
    font-family: 'montserrat';
    color:#24AA5A;
    font-size: 20px;
    align-items: center;
    padding: 0% 3% 0%;
    margin-top: 2%;
}
/* mission Vission CSS End Here ------------------------------------------------------------------- */

/* Parner CSS */
.partner-bg{
    background-color: #FFE38B;
}
.partner-padding{
    padding: 7% 0% 7%;
}
/* Parner CSS End Here ---------------------------------------------------------------------------- */

/* OUR Teams */
.desktop-hide{
    display: none;
}
.team-padding{
    padding: 10% 7% 0%;
}
.team-img-padding{
    padding: 3% 3% 0%;
}

.team-left-margin{
    margin-left: 3%;
}
.our-team{
    width: 18%;
    height: 42vh;
    margin-right: 1%;
    float: left;
    counter-increment: carousel-cell;
    color: black;
    /* border: 1px solid red; */
}
.team-img-plate{
    background-color: black;
    width: 96%;
    height: 30vh;
    border-radius: 180px;
    float: left;
    margin-left: 3%;
    box-shadow: 10px 10px 20px rgb(84, 109, 84);
}

.team-img1{
    background-image: url('../images/classify-reyan.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.team-img2{
    background-image: url('../images/classify-carl.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.team-img3{
    background-image: url('../images/classify-alden.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.team-img4{
    background-image: url('../images/classify-joel.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.team-img5{
    background-image: url('../images/classify-jerwin.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.team-img6{
    background-image: url('../images/classify-nitish.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.team-img7{
    background-image: url('../images/classify-kristine.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.team-img8{
    background-image: url('../images/classify-profile.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
div.team-name{
    padding: 20% 0% 0%;
    margin-top: 90%;
}
div.team-name h4{
    text-align: left;
    font-family: 'PT serif';
    color: #1F5234;
    margin-left: 4%;
}
div.team-name p{
    text-align: left;
    margin-left: 4%;
    font-size: 15px;
}




.team-cell {
    width: 240px;
    margin-right: 3%;
    border-radius: 120px;
    counter-increment: carousel-cell;
    color: black;
}
  /* cell number */
.team-cell:before {
    display: block;
    text-align: center;
    content: counter(carousel-cell);
    line-height: 200px;
    font-size: 80px;
    color: transparent;
}
.profile{
    /* background-image: url("../images/about-img1.png"); */
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;

    width: 245px;
    height: 240px;
    border-radius: 120px;
    background-color: black;
    margin-top: -80%;
    box-shadow: 3px 3px 10px rgb(28, 59, 28);
  }

  /* Reference */
  .carousel-cell {
    width: 245px;
    margin-right: 2%;
    border-radius: 120px;
    counter-increment: carousel-cell;
    color: black;
  }

  /* cell number */
  .carousel-cell:before {
    display: block;
    text-align: center;
    content: counter(carousel-cell);
    line-height: 200px;
    font-size: 80px;
    color: transparent;
  }

  div.mt-4 h4{
      text-align: left;
      font-family: 'PT serif';
      color: #1F5234;
  }
/* OUR Teams CSS End Here -------------------------------------------------------------------- */

/* gallery */
.gallery-padding{
    padding: 10% 7% 5%;
}
.gallery-img-padding{
    padding-bottom: 8%;
}
.gallery-image1{
    background-image: url('../images/Gallery/image1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 30% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.gallery-image2{
    background-image: url('../images/Gallery/image2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 20% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.gallery-image3{
    background-image: url('../images/Gallery/image3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 25% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.gallery-image4{
    background-image: url('../images/Gallery/image4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 15% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.gallery-image5{
    background-image: url('../images/Gallery/image1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 30% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.gallery-image6{
    background-image: url('../images/Gallery/image2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 20% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.gallery-image7{
    background-image: url('../images/Gallery/image3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 25% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.gallery-image8{
    background-image: url('../images/Gallery/image4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 15% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.gallery-bg{
    background-color: rgb(34, 99, 34, .1);
    /* background: linear-gradient(0deg, rgba(36, 170, 90, 0.8) 0%, rgba(36, 170, 90, 0) 80%); */
    height: 27vh !important;
    width: 100% !important;
    border-radius: 30px;
    padding: 5%;
    z-index: 999999;
}
p.gallery-bg-title{
    float: right;
    margin-top: 40%;
    margin-right: 5%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
    display: none;
    
}
p.gallery-bg-title1{
    float: right;
    margin-top: 63%;
    margin-right: 5%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
    display: none;
}
p.gallery-bg-title2{
    float: right;
    margin-top: 50%;
    margin-right: 5%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
    display: none;
}
p.gallery-bg-title3{
    float: right;
    margin-top: 88%;
    margin-right: 7%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
    display: none;
}
 .gallery-bg:hover .gallery-bg-title{
    display: block;
}
.gallery-bg:hover .gallery-bg-title1{
    display: block;
}
.gallery-bg:hover .gallery-bg-title2{
    display: block;
}
.gallery-bg:hover .gallery-bg-title3{
    display: block;
}
.gallery-bg:hover{
    background: linear-gradient(0deg, rgba(36, 170, 90, 0.8) 0%, rgba(36, 170, 90, 0) 80%);
    height: 27vh !important;
    width: 100% !important;
    border-radius: 30px;
    padding: 5%;
    box-shadow:3px 3px 10px #1F5234;
}
.services-indicators{
    background-color:#24AA5A;
    border: none;
    width: 5%;
    height: 1vh;
    margin-left: 1%;
    border-radius: 30px;
    margin-top: 20%;
}
div.carousel-indicators button.active{
    background-color: #1F5234;
}
button.services-indicators{
    margin-top: 1%;
}
.gallery-img-size1{
    height: 25vh !important;
    width: 20% !important;
    border-radius: 30px;
    float: left;
    margin-left: 1%;
}
.gallery-img-size2{
    height: 25vh !important;
    width:27% !important;
    border-radius: 30px;
    float: left;
    margin-left: 1%;
}
.gallery-indicators{
    background-color:#24AA5A;
    border: none;
    width: 5%;
    height: 1vh;
    margin-left: 1%;
    border-radius: 30px;
    /* margin-top: 50%; */
}
.galery-row{
    height: 100vh;
}
/* gallery CSS end here ------------------------------------------------------------------------------------ */

.card{
    border: none;
    text-align: left;
    /* padding: 10%; */
}

h5.card-title span{
    font-size: 40px;
    font-family: 'PT Serif';
    color: #24AA5A;
}
h6.card-subtitle{
    font-size: 20px;
    font-family: 'montserrat';
    color: #1F5234;
}
i.fas{
    color: white;
}
i.fas span{
    font-size: 20px;
    font-family: 'montserrat';
    color: #1F5234;
    font-weight: normal;
}
