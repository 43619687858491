/* General CSS --------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=PT+Serif:wght@700&family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
*{
    font-family: 'Montserrat', sans-serif;
    font-family: 'PT Serif', serif;
    font-family: 'Roboto', sans-serif;
}

.grecaptcha-badge {
    display:none !important;
}


.view-height{
    height: 90vh;
}
p{
    font-family: 'Montserrat';
    text-align: justify;
}
.card-shadow{
    box-shadow:10px 10px 20px rgba(31, 82, 52, .3);
}
.div-padding{
    /* padding: 5% 3% 5%; */
    margin-top: 5%;
}
.title-gap{
    margin-top: 4%;
}


.hide-none{
    display:none;
}
/* .ipad-hide{
    display: block;
} */
.ipad-show{
    display: none;
}



.desktop-hide{
    display: block;
}
.ipad-hide{
    display: none;
}
.mobile-hide{
    display: none;
}


/* End off General CSS here --------------------------------------------------------------------------*/


/* Header CSS ---------------------------------------------------------------------------*/
.navbar{
    background-color: white;
    border-top: 5px solid #24AA5A;
    box-shadow:5px, 5px, 10px, black;
    font-family: 'Lora';
    /* width: 100%; */
}
.navbar-brand{
    padding: 0px;
    width: 10%;
}
.header-logo{
    width: 70%;
}
li.nav-item a{
    margin-right: 50px;
    color: #394765;
    text-align: center;
}
div.collapse ul.navbar-nav li.nav-item a:hover{
    color: #24AA5A;
    font-weight: 600;
    text-decoration: none !important;
}
div.collapse ul.navbar-nav li.active a{
    color: #24AA5A;
    font-weight: 700;
    border-bottom: 3px solid #24AA5A;
    text-decoration: none !important;
}
div.collapse ul.navbar-nav button{ 
    font-size: 15px;
    color: white;
    border-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #24AA5A;
    border: none;
}
li.nav-item .navlink-text{
    color: #394765 !important;
    text-decoration: none !important;
    padding: 0px 5px 8px;
}
li.nav-item .navlink-text:hover{
    color: #24AA5A !important;
    text-decoration: none !important;
    padding: 0px 5px 8px;
}
.main-nav-active{
    border-bottom: 3px solid #24AA5A !important;
    text-decoration: none !important;
}
.d-grid{
    text-align: right;
    border: none;
    /* margin-right: 0%; */
    text-decoration: none;
}

/* End of Header CSS here ------------------------------------------------------------------*/


/* --------------------------------- Home page ---------------------------------------------*/
/* Landing Image */
div.carousel-item img{
    height: 100vh;
    width: 100vw;
    margin-top: 50px;
}

.carousel-caption{
    position: absolute;
    top: 30%;
    left: 10%;
    text-align: left;
}

p.carousel-txt1{
    font-size: 25px !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    color: #FFD03D;
    margin-left: .9% !important;
}
p.carousel-txt2{
    font-size: 80px !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    margin-top: -2.7% !important;
}
p.carousel-txt3{
    font-size: 170px !important;
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: normal;
    margin-top: -6% !important;
}
button.carousel-btn{
    position: absolute;
    background-color: #24AA5A;
    color: #ffffff;
    font-size: 25px;
    font-family: 'PT Serif';
    padding: 1% 5% 1%;
    border-radius: 30px;
    border: none;
    margin-top: -3%;
}
button.carousel-btn:hover{
    background-color: #FFD03D;
    color: #ffffff;
    font-weight: bold;
}
/* Landing page CSS end ------------------------------------------------------------------------ */

/* Our Services Statistics */
p.services-txt{
    font-size: 100px;
    font-family: 'PT Serif' !important;
    font-style: normal;
    color: #1F5234;
    text-align: left;
    margin-top: -1%;
}
div.statistic{
    padding: 3%;
    border-radius: 30px;
    box-shadow:10px 10px 20px rgba(31, 82, 52, .3);
}
p.statistic-txt{
    font-size: 50px;
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: normal;
    color: #1F5234;
    text-align: center;
}
p.statistic-txt span {
    display: inline-block;
    position: relative;  
}
p.statistic-txt span:before, p.statistic-txt span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 4px solid #24AA5A;
    top: 50%;
    width: 115px;
}
.statistic-txt span:before {
    right: 100%;
    margin-right: 15px;
}
.statistic-txt span:after {
    left: 100%;
    margin-left: 15px;
}
.stat-category{
    font-size: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    color: #1F5234;
    text-align: center;
}
.cat-value{
    font-size: 150px;
    font-family: 'pt serif';
    color: #1F5234;
    text-align: center;
    margin-top: -15%;
}
.stat-img{
    margin-top: -5%;
    margin-left: 15%;
}
p.left-padding{
    padding: 0% 0% 0% 10%;
}
/* Our Services Statistics CSS End here -------------------------------------------------------------- */

/* Services */
.services-image1{
    background-image: url('../images/Gallery/image1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 30% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.services-image2{
    background-image: url('../images/Gallery/image2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 20% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.services-image3{
    background-image: url('../images/Gallery/image3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 25% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}
.services-image4{
    background-image: url('../images/Gallery/image4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 15% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
}


.services-image5{
    background-image: url('../images/Gallery/image1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 30% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.services-image6{
    background-image: url('../images/Gallery/image2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 20% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.services-image7{
    background-image: url('../images/Gallery/image3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 25% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.services-image8{
    background-image: url('../images/Gallery/image4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vh !important;
    width: 15% !important;
    border-radius: 30px;
    float: left;
    margin-left: 2%;
    margin-top: 2%;
}
.services-bg{
    /* background-color: rgb(34, 99, 34, .5); */
    background: linear-gradient(0deg, rgba(36, 170, 90, 0.5) 0%, rgba(36, 170, 90, 0) 80%);
    height: 27vh !important;
    width: 100% !important;
    border-radius: 30px;
    padding: 5%;
    
}
.services-bg:hover{
    border-radius: 30px;
    box-shadow: 3px 3px 15px rgb(53, 77, 53);
}


p.services-bg-title{
    float: right;
    margin-top: 40%;
    margin-right: 5%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
}
p.services-bg-title1{
    float: right;
    margin-top: 63%;
    margin-right: 5%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
}
p.services-bg-title2{
    float: right;
    margin-top: 50%;
    margin-right: 5%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
}
p.services-bg-title3{
    float: right;
    margin-top: 88%;
    margin-right: 7%;
    color: white;
    font-size: 28px;
    font-weight: 700;
    font-family: 'PT serif';
}
.services-indicators{
    background-color:#24AA5A;
    border: none;
    width: 5%;
    height: 1vh;
    margin-left: 1%;
    border-radius: 30px;
    margin-top: 20%;
}
div.carousel-indicators button.active{
    background-color: #1F5234;
}
/* Services CSS End ----------------------------------------------------------------------------------------*/


/* partners */
.partners-padding{
    /* padding: 6% 0% 5%; */
    margin-top: 50px !important;
}
.parners-card{
    box-shadow:10px 10px 20px rgba(31, 82, 52, .3);
    border-radius: 30px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 20vh;
}
/* .parners-logo{
    width: 100%;
    height: 10vh;
} */
/* partners CSS End Here --------------------------------------------------------------------------*/

/* our Products */
.our-products{
    background-color: #24AA5A;
    padding: 3%;
    color: white;
    margin-top: 6%;
}
h2.product-title{
    font-size: 100px;
    font-family: 'PT serif';
    text-align: left;
}
.product-content{
    font-size: 20px;
    font-family: 'Montserrat';
}
.op-padding{
    padding: 3% 5% 3%;
    text-align: left;
}
.op-padding-bottom{
    padding: 16px 20px 25px;
    text-align: left;
}

div.op-padding h3{
    font-size: 25px;
    font-family: 'PT serif';
    color: #FFD03D;
    margin-left: 1%;
}
div.op-padding-bottom h3{
    font-size: 25px;
    font-family: 'PT serif';
    color: #FFD03D;
    margin-left: 1%;
}
.product-grid{
    height: 50vh !important;
    width: 15% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
}
.product-img{
    height: 40vh !important;
    width: 100% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
}
/* note for our products need a high resolution (option 1 set in css using background url) */
.product-img-bg1{
    background-image: url('../images/Gallery/image1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh !important;
    width: 100% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
    margin-top: 17%;
}
.product-img-bg2{
    background-image: url('../images/Gallery/image2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh !important;
    width: 100% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
    margin-top: 17%;
}
.product-img-bg3{
    background-image: url('../images/Gallery/image3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh !important;
    width: 100% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
    margin-top: 17%;
}
.product-img-bg4{
    background-image: url('../images/Gallery/image4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh !important;
    width: 100% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
    margin-top: 17%;
}
.product-img-bg5{
    background-image: url('../images/Gallery/image5.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh !important;
    width: 100% !important;
    border-radius: 30px;
    float: left;
    margin-left: 4%;
    margin-top: 17%;
}





div.product-grid h1{
    color: #1F5234;
    font-size: 20px;
    font-family: 'PT serif';
}
div.image-title{
    position: absolute;
    bottom: 0%;
    margin-left: 1%;
}

/* our Products End Css -------------------------------------------------------------------------- */

/* why choose nutrihogs? */
.choose-nutrihogs{
    height: 50vh;
    width: 100%;
}
.carousel-img-bg{
    background: linear-gradient(270deg, rgba(31, 82, 52, 0.7) 0%, rgba(31, 82, 52, 0) 100%);
    position: absolute;
    top: 4.5%;
    right: 0%;
    width: 100%;
}
.carousel-img-text{
    /* background-color: rgb(20, 68, 30, .5); */
    border-radius: 30px;
    width: 30%;
    padding: 2%;
    position: absolute;
    right: 13%;
    margin-top: 11%;
}
div.carousel-img-text h5{
    color: #FFD03D;
    font-size: 25px;
    font-family: 'PT serif';
}
div.carousel-img-text p{
    font-size: 20px;
    font-family: 'Montserrat';
}
.choose-nutri{
    padding: 5% 5% 0%;
}
/* why choose nutrihogs? CSS end Here ------------------------------------------------------------------ */

/* News */
.news-top{
    padding: 7% 5% 1%;
}
.news-img-padding{
    padding: 0% 0% 10%;
}
.news-grid{
    width: 18%;
    height: 60vh;
    margin-right: 1.5%;
    float: left;
    counter-increment: carousel-cell;
    color: black;
    /* border: 1px solid red; */
}
.news-img-plate{
    background-color: black;
    width: 96%;
    height: 40vh;
    border-radius: 30px;
    float: left;
    margin-left: 3%;
    box-shadow: 10px 10px 20px rgb(84, 109, 84);
}
.news-img1{
    background-image: url('../images/news1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.news-img2{
    background-image: url('../images/news2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.news-img3{
    background-image: url('../images/news3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.news-img4{
    background-image: url('../images/news4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
div.news-name{
    padding: 30% 0% 0%;
    margin-top: 110%;
}
div.news-name h4{
    text-align: left;
    font-family: 'PT serif';
    color: #1F5234;
    margin-left: 4%;
}
div.news-name p{
    text-align: left;
    margin-left: 4%;
    font-size: 15px;
}
  /* News css END HERE ------------------------------------------------------------------*/
/* Content Css End Here -----------------------------------------------------------------*/

/* Footer */
.footer-submain-bg{
    background-color: #383838;
    color: white;
    text-align: left;
    font-weight: normal;
    padding: 5%;
}
div.footer-submain-bg div.col-md-3{
    padding: 0% 3% 0%;
}
.footer-logo{
    width: 40%;
}
.footer-title{
    font-size: 36px;
    font-family: 'PT serif';
}
div.col-md-9 .footer-sub-title{
    font-size: 20px;
    font-weight: bold;
    font-family: 'PT serif';
    color: #24AA5A;
}
.footer-content{
    font-size: 20px;
    font-family: 'Montserrat';
    text-align: left;
}
.footer-highlight{
    color: #24AA5A;
    font-size: 20px;
}
.news-content{
    font-size: 12px;
    font-family: 'montserrat';
    text-align: left;
}
.service-content{
    font-size: 15px;
    font-weight: bold;
    font-family: 'PT serif';
    color:#24AA5A;
}
.footer-icon{
    color:#C4C4C4;
    margin-left: 5%;
    margin-top: 30%;
}
.footer-main-bg{
    background-color: #2C2B2B;
}
.footer-main-text{
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: 'PT serif';
    font-size: 22px;
    padding: 1% 1% 1% 1%;
}

/* ---------------------------------------------------------------------------------------------------------------- */

/* Media Query Starts Here ---------------------------------------------------------------- */

/* Desktop 1650 x 1050 */
@media (min-width: 1650px) and (max-width: 1919px) {
    /* --------------- for Home ------------- */
    p.carousel-txt2{
        font-size: 80px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        margin-top: -3.5% !important; 
    }
    p.carousel-txt3{
        font-size: 170px;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: normal;
        margin-top: -7% !important;
    }
    .news-grid{
        width: 18%;
        height: 58vh;
        margin-right: 1.5%;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
    }
    div.news-name{
        padding: 45% 0% 0%;
        margin-top: 110%;
    }
    /* --------------- for About us ------------- */
    .team-img-plate{
        background-color: black;
        width: 96%;
        height: 25vh;
        border-radius: 180px;
        float: left;
        margin-left: 3%;
        box-shadow: 10px 10px 20px rgb(84, 109, 84);
    }
    div.team-name{
        padding: 15% 0% 0%;
        margin-top: 90%;
    }
    .our-team{
        width: 18%;
        height: 38vh;
        margin-right: 1%;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
    }

    /* --------------- for contact us ------------- */
    .cont-row-padding{
        padding: 11% 10% 9% 5%;
    }
    .map-padding{
        padding: 4% 10% 10%;
    }
    /* --------------- for About us ------------- */
    
}
/* Desktop 1650 x 1050 CSS End Here */


/* Desktop 1366px x 1649px */
@media (min-width: 1366px) and (max-width: 1649px) {
    /* --------------- for Home ------------- */
    p.carousel-txt1{
        font-size: 20px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        color: #FFD03D;
        margin-left: .8% !important;
        margin-top: -2% !important;
    }
    p.carousel-txt2{
        font-size: 70px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        margin-top: -4% !important;
    }
    p.carousel-txt3{
        font-size: 130px !important;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: normal;
        margin-top: -7.5% !important;
    }
    button.carousel-btn{
        position: absolute;
        background-color: #24AA5A;
        color: #ffffff;
        font-size: 20px !important;
        font-family: 'PT Serif';
        padding: 1% 6% 1% !important;
        border-radius: 30px;
        border: none;
        margin-top: -3% !important;
    }
    .title-gap{
        margin-top: 1%;
    }
    .stat-category{
        font-size: 35px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        color: #1F5234;
        text-align: center;
    }
    .cat-value{
        font-size: 130px;
        font-family: 'pt serif';
        color: #1F5234;
        text-align: center;
        margin-top: -15%;
        
    }
    p.services-bg-title{
        float: right;
        margin-top:30%;
        margin-right: 5%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title1{
        float: right;
        margin-top: 48%;
        margin-right: 5%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title2{
        float: right;
        margin-top: 37%;
        margin-right: 5%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title3{
        float: right;
        margin-top: 65%;
        margin-right: 7%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    .product-grid{
        height: 52vh !important;
        width: 15% !important;
        border-radius: 30px;
        float: left;
        margin-left: 4%;
        /* border: 1px solid red; */
    }
    .parners-logo-cpf{
        height: 12vh;
    }

    h2.product-title{
        font-size: 80px;
        font-family: 'PT serif';
        text-align: left;
    }
    div.op-padding h3{
        font-size: 21px;
        font-family: 'PT serif';
        color: #FFD03D;
        margin-left: 1%;
    }
    div.choose-nutrihogs div.carousel-item img{
        width: 100vw;
        margin-top: 15px;
    }
    .carousel-img-bg{
        background: linear-gradient(270deg, rgba(31, 82, 52, 0.7) 0%, rgba(31, 82, 52, 0) 100%);
        position: absolute;
        top:2%;
        right: 0%;
        width: 100%;
    }
    .carousel-img-text{
        /* background-color: rgb(20, 68, 30, .5); */
        border-radius: 30px;
        width: 40%;
        padding: 2%;
        position: absolute;
        right: 13%;
        margin-top: 6%;
    }

    .news-grid{
        width: 18%;
        height: 70vh;
        margin-right: 1.5%;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
    }
    div.news-name{
        padding: 30% 0% 0%;
        margin-top: 70% !important;
    }
    div.news-name h4{
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
        font-size: 20px;
        margin-top: 20% !important;
    }
    div.news-name p{
        text-align: left;
        margin-left: 4%;
        font-size: 16px;
    }

    /* --------------- for About us ------------- */
    p.about-content{
        font-size: 18px;
        font-family: 'Montserrat';
        margin-top: 3%;
        color: #1F5234;
    }
    p.text-paragrap1{
        font-size: 18px;
        font-family: 'montserrat';
        color:#24AA5A;
    }
    p.text-paragrap{
        font-size: 18px;
        font-family: 'montserrat';
        color:#1F5234;
    }
    .our-team{
        width: 18%;
        height: 52vh;
        margin-right: 1%;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
    }
    .team-img-plate{
        background-color: black;
        width: 96%;
        height: 34vh;
        border-radius: 180px;
        float: left;
        margin-left: 3%;
        box-shadow: 10px 10px 20px rgb(84, 109, 84);
    }
    div.team-name{
        padding: 15% 0% 0%;
        margin-top: 90%;
    }
    div.team-name h4{
        font-size: 18px !important;
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
    }
    div.team-name p{
        font-size: 14px !important;
        text-align: left;
        margin-left: 4%;
        /* font-size: 15px; */
    }
    .gallery-bg{
        background-color: rgb(34, 99, 34, .1);
        /* background: linear-gradient(0deg, rgba(36, 170, 90, 0.8) 0%, rgba(36, 170, 90, 0) 80%); */
        height: 27vh !important;
        width: 100% !important;
        border-radius: 30px;
        padding: 5%;
        z-index: 999999;
    }
    p.gallery-bg-title{
        float: right;
        margin-top: 28%;
        margin-right: 5%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
        display: none;
        
    }
    p.gallery-bg-title1{
        float: right;
        margin-top: 45%;
        margin-right: 5%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
        display: none;
    }
    p.gallery-bg-title2{
        float: right;
        margin-top: 35%;
        margin-right: 5%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
        display: none;
    }
    p.gallery-bg-title3{
        float: right;
        margin-top: 63%;
        margin-right: 7%;
        color: white;
        font-size: 28px;
        font-weight: 700;
        font-family: 'PT serif';
        display: none;
    }
     .gallery-bg:hover .gallery-bg-title{
        display: block;
    }
    .gallery-bg:hover .gallery-bg-title1{
        display: block;
    }
    .gallery-bg:hover .gallery-bg-title2{
        display: block;
    }
    .gallery-bg:hover .gallery-bg-title3{
        display: block;
    }
    .gallery-bg:hover{
        background: linear-gradient(0deg, rgba(36, 170, 90, 0.8) 0%, rgba(36, 170, 90, 0) 80%);
        height: 27vh !important;
        width: 100% !important;
        border-radius: 30px;
        padding: 5%;
        box-shadow:3px 3px 10px #1F5234;
    }

    /* --------------- for contact us ------------- */
    .top{
        margin-top: 5%; 
    }
    .cont-row-padding{
        padding: 6% 8% 6%;
    }
    .map-padding{
        padding: 4% 10% 10%;
    }
    .col-right-padding{
        padding-right: 2%;
    }
    .col-left-padding{
        padding-left: 2%;
    }

    /* --------------- for About us ------------- */
    .row-padding{
        padding: 4% 10% 3%;
    }
    .about-image {
        background-image: url("../images/about-img1.png");
        background-attachment: fixed;
        background-position: center; 
        background-repeat: no-repeat;
        background-size: cover;
        height: 45vh;
    }
    .green-bg{
        background-color: green;
        height: 45vh;
        opacity: 40%;
    }
    .abt-row-padding{
        padding: 8% 0% 8%;
    }
    .team-padding{
        padding: 7% 7% 0%;
    }
    .team-img-padding{
        padding: 1% 3% 0%;
    }
}
/* Desktop 1366px x 1649px CSS End Here */



























/* IPAD 768px x 1024px CSS End Here */
@media (min-width: 768px) and (max-width: 1024px)  {
    .desktop-hide{
        display: none;
    }
    .ipad-hide{
        display: block;
    }

    
    .top{
        margin-top: 10%;
    }

    /* navigation */
    li.nav-item a{
        margin-right: 50px;
        color: #394765;
        text-align: center;
        padding: 3% 0% 2% !important;
    }
    .main-nav-active{
        border-bottom: none;
    }
    li.nav-item .main-nav-active{
        border-bottom: none !important;
        color: #24AA5A !important;
        padding: 3% 0% 2%;
    }



    /* landing */
    div.carousel-item img{
        height: 85vh;
        width: 100vw;
        margin-top: 50px;
    }
    p.carousel-txt1{
        font-size: 20px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        color: #FFD03D;
        margin-left: .9% !important;
    }
    p.carousel-txt2{
        font-size: 50px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        margin-top: -5% !important;
    } 
    p.carousel-txt3{
        font-size: 100px !important;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: normal;
        margin-top: -10% !important;
    }
    p.services-txt{
        font-size: 80px;
        font-family: 'PT Serif' !important;
        padding: 0px 16px 0px;
        margin-top: 3%;
    }
    .stat-category{
        font-size: 40px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        color: #1F5234;
        text-align: center;
    }
    .cat-value{
        font-size: 100px;
        font-family: 'pt serif';
        color: #1F5234;
        text-align: center;
        margin-top: -15%;
        
    }
    .stat-img{
        margin-top: -18%;
        margin-left: 5%;
    }
    .cat-value1{
        font-size: 180px;
        font-family: 'pt serif';
        color: #1F5234;
        text-align: center;
        margin-top: -10%;
    }

    
    .services-image1{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 36% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
    }
    .services-image2{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 25% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
    }
    .services-image3{
        background-image: url('../images/Gallery/image3.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 31% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
    }
    .services-image4{
        background-image: url('../images/Gallery/image4.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 31% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
        margin-top: 2%;
    }
    .services-image5{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 36% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
        margin-top: 2%;
    }
    .services-image6{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 25% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
        margin-top: 2%;
    }
    p.services-bg-title{
        float: left;
        margin-top: 87%;
        margin-left: 5%;
        color: white;
        font-size: 30px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title1{
        float: left;
        margin-top: 128%;
        margin-left: 5%;
        color: white;
        font-size: 30px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title3{
        float: left;
        margin-top: 102%;
        margin-left: 5%;
        color: white;
        font-size: 30px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    button.carousel-control-prev5, button.carousel-control-next5{
        background-color: transparent;
         border: none;
         height: 5vh;
         margin-top: 1%;
         padding: 0%;
         border-style: 1px solid red;
     }
     button.carousel-control-prev5 i.fa-arrow-left{
         color: #1F5234;
         background-color: transparent;
         /* font-size: 100%; */
     }
     button.carousel-control-next5 i.fa-arrow-right{
         color: #1F5234;
         background-color: transparent;
     }
     div.carousel-indicators button.active {
        background-color: transparent;
    }
    
    .parners-card{
        box-shadow:none;
        border-radius: 30px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        height: 10vh;
    }
    .parners-logo{
        width: 100% !important;
        margin-top: -5%;
    }
    .parners-logo2{
        width: 100%;
        margin-top: 0%;
    }
    .parners-logo3{
        width: 60%;
        margin-top: 0%;
    }
    .parners-logo4{
        width: 75%;
        margin-top: 0%;
    }
    h2.product-title{
        font-size: 50px;
        padding: 0px 0px 0px;
        font-family: 'PT serif';
        text-align: left;
    }
    .product-content{
        font-size: 20px;
        font-family: 'Montserrat';
    }
    .product-grid{
        height: 43vh !important;
        width: 28% !important;
        border-radius: 30px;
        float: left;
        margin-left: 3.8%;
    }
    .product-img-bg1{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 35vh !important;
        width: 100% !important;
        border-radius: 30px;
        float: left;
        margin-left: 4%;
        margin-top: 17%;
    }
    .product-img-bg2{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 35vh !important;
        width: 100% !important;
        border-radius: 30px;
        float: left;
        margin-left: 4%;
        margin-top: 17%;
    }
    .product-img-bg3{
        background-image: url('../images/Gallery/image3.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 35vh !important;
        width: 100% !important;
        border-radius: 30px;
        float: left;
        margin-left: 4%;
        margin-top: 17%;
    }
    p.services-txt-ipad{
        font-size: 50px;
        font-family: 'PT Serif' !important;
        padding: 0px 16px 0px;
        margin-top: 3%;
        color: #1F5234;
    }
    .carousel-img-bg{
        background: linear-gradient(270deg, rgba(31, 82, 52, 0.7) 0%, rgba(31, 82, 52, 0) 100%);
        position: absolute;
        top: 5.3%;
        right: 0%;
        width: 100%;
    }
    .carousel-img-text{
        border-radius: 30px;
        width: 90%;
        padding: 2%;
        position: absolute;
        right: 14%;
        margin-top: -40%;
    }
    div.carousel-img-text h5{
        position: absolute;
        margin-top: -10%;
    }
    .news-grid{
        height: 52vh !important;
        width: 30% !important;
        border-radius: 30px;
        float: left;
        margin-left: .5%;
        /* border: 1px solid red; */
    }
    .news-img-plate{
        background-color: black;
        width: 96%;
        height: 33vh;
        border-radius: 35px;
        float: left;
        box-shadow: 5px 5px 15px rgb(84, 109, 84);
    }
    div.news-name{
        padding: 30% 0% 0%;
        margin-top: 110% !important;
    }
    div.news-name h4{
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
        font-size: 20px;
        margin-top: 20% !important;
    }
    div.news-name p{
        text-align: left;
        margin-left: 4%;
        font-size: 16px;
    }










/* CONTACT */
    h5.card-title{
        font-size: 25px;
        font-family: 'PT Serif';
        color: #1F5234;
    }
    h5.card-title span{
        font-size: 25px;
        font-family: 'PT Serif';
        color: #24AA5A;
    }
    h6.card-subtitle{
        font-size: 18px;
        font-family: 'montserrat';
        color: #1F5234;
    }
    div.card-body i.fas span{
        font-size: 20px;
        line-height: 25px;
        /* color: #1F5234 !important; */
    }

    h5.form-title{
        font-size: 25px;
        font-family: 'PT Serif';
        color: #ffffff; 
    }
    .form-bg{
        background-color: #24AA5A;
        border-radius: 30px;
        text-align: left;
        padding: 8% !important;
    }
    .form-text{
        font-size: 20px;
        font-family: 'montserrat';
        color: #ffffff;
    }
    .map-padding{
        padding: 5% 9% 12%;
    }

    /* about */
    .vm-img{
        padding: 0% 5% 10%;
    }
    h5.title{
        font-size: 30px;
    }
    .partner-logo{
        width: 50%;
    }
    .partner-logo1{
        width: 25%;
    }
    .our-team{
        width: 27%;
        height: 35vh;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
        margin-left: 3%;
    }
    div.team-img-plate{
        background-color: black;
        width: 100%;
        height: 19vh;
        border-radius: 180px;
        float: left;
        margin-left: 0%;
        box-shadow: 3px 3px 10px rgb(53, 77, 53);
    }

    button.carousel-control-prev, button.carousel-control-next{
       background-color: transparent;
        border: none;
        height: 5vh;
        margin-top: 40%;
        padding: 0%;
    }
    button.carousel-control-prev i.fa-arrow-left{
        color: #1F5234;
    }
    button.carousel-control-next i.fa-arrow-right{
        color: #1F5234;
    }
    div.team-name h4{
        font-size: 16px;
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
    }
    div.team-name p{
        font-size: 12px !important;
        text-align: left;
        margin-left: 4%;
        /* font-size: 15px; */
    }

    .gallery-image1{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 36% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
    }
    .gallery-image2{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 25% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
    }
    .gallery-image3{
        background-image: url('../images/Gallery/image3.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 30% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
    }
    .gallery-image4{
        background-image: url('../images/Gallery/image4.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 30% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
        margin-top: 2%;
    }
    .gallery-image5{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 36% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
        margin-top: 2%;
    }
    .gallery-image6{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 27vh !important;
        width: 25% !important;
        border-radius: 30px;
        float: left;
        margin-left: 2%;
        margin-top: 2%;
    }
    button.carousel-control-prev1, button.carousel-control-next1{
        background-color: transparent;
         border: none;
         height: 5vh;
         margin-top: 10%;
         padding: 0%;
        
     }
    button.carousel-control-prev1 i.fa-arrow-left{
        color: #1F5234;
        left: 0px;
    }
    button.carousel-control-next1 i.fa-arrow-right{
        color: #1F5234;
        margin-right: 0%;
    }





    /* footer */
    p.footer-content{
        font-size: 16px;
        font-family: 'Montserrat';
        text-align: left;
    }
    .footer-icon-fb{
        color:#C4C4C4;
        margin-left: 0%;
        margin-top: 4%;
    }
    .footer-icon{
        color:#C4C4C4;
        margin-left: 5%;
        margin-top: 4%;
    }
    .footer-main-bg{
        background-color: #2C2B2B;
    }
    p.footer-main-text{
        color: white;
        text-align: center;
        font-weight: bold;
        font-family: 'PT serif';
        font-size: 22px;
        padding: 1% 0% 0%;
    }
}
 
/* ipad Pro */
@media (min-width: 1024px) and (max-height: 1366px){
    p.c-txt1{
        font-size: 25px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        color: #FFD03D;
        margin-left: .9% !important;
    }
    p.c-txt2{
        font-size: 65px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        margin-top: -4% !important;
    }
    p.c-txt3{
        font-size: 115px !important;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: normal;
        margin-top: -7% !important;
    }



    button.carousel-btn{
        position: absolute;
        background-color: #24AA5A;
        color: #ffffff;
        font-size: 30px;
        font-family: 'PT Serif';
        padding: 2% 10% 2%;
        border-radius: 30px;
        border: none;
        margin-top: -3%;
    }
}


/* iphone 375px x 812px */
@media (min-width: 375px) and (max-width: 767px) {
    /* ------------------------------------------------------ Home Mobile Responsive */
    .desktop-hide{
        display: none !important;
    }
    .mobile-hide{
        display: block !important;
    }


    
    li.nav-item a{
        margin-right: 50px;
        color: #394765;
        text-align: right;
    }
    .header-logo{
        width: 100% !important;
    }
    li.nav-item .nav-top{
        margin-top: 10px;
    }
    /* navlink-text */
    li.nav-item .navlink-text{
        padding:1% 5% 0%;
    }
    li.nav-item .main-nav-active{
        background-color: #24AA5A;
        color: white !important;
        border-bottom: none !important;
        border-radius: 30px;
        padding:3% 5% 3%;
    }

    li.nav-item .main-nav-active1{
        border-bottom: none !important;
        /* color: #24AA5A !important; */
        color: white !important;
        background-color: #24AA5A;
        padding:3% 5% 3%;
        border-radius: 30px;
    }
    
    .navlink-text{
        width: 100% !important;
    }
    .form-control{
        text-align: right;
        border: none;
        margin-right: 0%;
    }
    .d-grid{
        text-align: right;
        border: none;
        margin-right: 0%;
        text-decoration: none;
    }

    div.carousel-item img{
        height: 45vh;
        margin-top: 50px;
    }
    p.car-txt1{
        font-size: 13px !important;
        font-family: 'Montserrat' !important;
        margin-top: -5% !important;
        color: #FFD03D;
    }
    .car-txt2{
        font-size: 25px !important;
        font-family: 'Montserrat' !important;
        font-style: normal;
        font-weight: normal;
        margin-top: -7% !important;
    }
    p.car-txt3{
        font-size: 50px !important;
        font-family: 'PT Serif';
        margin-top: -11% !important;
    }
    button.carousel-btn{
        position: absolute;
        background-color: #24AA5A;
        color: #ffffff;
        font-size: 20px;
        font-family: 'PT Serif';
        padding: 2% 10% 2%;
        border-radius: 30px;
        border: none;
        margin-top: -5%;
    }
    p.services-txt{
        font-size: 25px;
        font-family: 'PT Serif' !important;
        padding: 0px 16px 0px;
        margin-top: 3%;
    }
    p.mobile-serv-txt{
        font-size: 24px;
        font-family: 'PT Serif' !important;
        padding: 0px 16px 0px;
    }
    div.statistic{
        padding: 3% !important;
        border-radius: 0px;
        box-shadow:none;
        /* box-shadow:10px 10px 20px rgba(31, 82, 52, .3); */
    }
    p.statistic-txt{
        font-size: 20px;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: normal;
        color: #1F5234;
        text-align: center;
    }
    p.statistic-txt span {
        display: inline-block;
        position: relative;  
    }
    p.statistic-txt span:before, p.statistic-txt span:after {
        content: "";
        position: absolute;
        height: 0px;
        border-bottom: 4px solid #24AA5A;
        top: 50%;
        width: 50%;
    }
    .stat-category{
        font-size: 20px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        color: #1F5234;
        text-align: center;
    }
    .cat-value{
        font-size: 50px;
        font-family: 'pt serif';
        color: #1F5234;
        text-align: center;
        margin-top: -15%;
        
    }
    .cat-value1{
        font-size: 80px;
        font-family: 'pt serif';
        color: #1F5234;
        text-align: center;
        margin-top: -15%;
        
    }
    .border-left{
        border-left: 3px solid #24AA5A;
        color:#24AA5A;
        font-size: 20px;
        align-items: center;
        padding: 0% 3% 0%;
        margin-top: 2%;
    }
    .stat-img{
        margin-top: -25%;
        margin-left: 0%;
    }
    .div-padding{
        /* padding: 5% 0% 5%; */
        margin-top: 5%;
    }
    .img-gap{
        margin-left: 3% !important;
    }
    .mobile-img-plate1{
        height: 20vh !important;
        width: 58% !important;
        border-radius: 30px;
        float: left;
        margin-left: 0%;
        margin-top: 3%;
    }
    .mobile-img-plate2{
        height: 20vh !important;
        width: 38% !important;
        border-radius: 30px;
        float: left;
        margin-left: 0%;
        margin-top: 3%;
    }
    .services-bg1{
        background: linear-gradient(0deg, rgba(36, 170, 90, 0.5) 0%, rgba(36, 170, 90, 0) 80%);
        height: 20vh !important;
        width: 100% !important;
        border-radius: 30px;
        padding: 5%;
    }
    .services-bg2{
        background: linear-gradient(0deg, rgba(36, 170, 90, 0.5) 0%, rgba(36, 170, 90, 0) 80%);
        height: 20vh !important;
        width: 100% !important;
        border-radius: 30px;
        padding: 5%;
    }
    .services-image1{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    button.carousel-control-prev3, button.carousel-control-next3{
        background-color: transparent;
         border: none;
         height: 5vh;
         margin-top: 1%;
         padding: 0%;
         /* border-style: 1px solid red; */
     }
     button.carousel-control-prev3 i.fa-arrow-left{
         color: #1F5234;
     }
     button.carousel-control-next3 i.fa-arrow-right{
         color: #1F5234;
     }
     .partners-padding{
        /* padding: 6% 0% 5%; */
        margin-top: 50px !important;
    }
    
    .title-gap{
        margin-top: 4% !important;
    }
    .services-title-gap{
        margin-top: -12% !important;
    }
    .sub-title-gap{
        margin-top: -20% !important;
    }
    h2.product-title{
        font-size: 45px;
        padding: 20px 0px 0px;
        font-family: 'PT serif';
        text-align: left;
    }
    .product-content{
        font-size: 16px;
        font-family: 'Montserrat';
    }
    p.services-bg-title{
        float: left;
        margin-top: 65%;
        margin-left: 5%;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title1{
        float: left;
        margin-top: 100%;
        margin-left: 5%;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    .parners-card{
        /* box-shadow:10px 10px 20px rgba(35, 0, 82, 0.3); */
        box-shadow:none;
        border-radius: 0px;
        padding: 0%;
        margin-left: auto;
        margin-right: auto;
        height: 10vh;
    }
    .parners-logo{
        width: 80%;
        margin-top: -5%;
    }
    .parners-logo2{
        width: 90%;
        margin-top: -18% !important;
    }
    .parners-logo3{
        width: 80%;
        margin-top: -25%;
    }
    .parners-logo4{
        width: 32%;
        margin-top: -17%;
    }
    .parners-logo5{
        width: 43%;
        margin-top: -17%;
    }
    .our-products1{
        background-color: #24AA5A;
        /* padding: 3%; */
        color: white;
        margin-top: 6%;
    }
    .product-grid{
        height: 34vh !important;
        width: 40% !important;
        border-radius: 30px;
        float: left;
        margin-left: 5%;
    }
    .product-img-bg1{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 25vh !important;
        float: left;
        margin-left: 5%;
        margin-top: 17%;
    }
    .product-img-bg2{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 25vh !important;
        float: left;
        margin-left: 5%;
        margin-top: 17%;
    }
    div.image-title{
        position: absolute;
        top:85%;
        bottom: 0%;
        margin-left: 3%;
    }
    div.choose-nutrihogs div.carousel-item img{
        width: 100vw;
        margin-top: 15px;
    }
    .carousel-img-bg{
        background: linear-gradient(270deg, rgba(31, 82, 52, 0.7) 0%, rgba(31, 82, 52, 0) 100%);
        position: absolute;
        top: 4.5%;
        right: 0%;
        width: 100%;
        height: 45vh;
    }
    .carousel-img-text{
        /* background-color: rgb(20, 68, 30, .5); */
        border-radius: 30px;
        width: 90%;
        padding: 2%;
        position: absolute;
        right: 14%;
        /* margin-top: 43%; */
    }
    div.carousel-img-text h5{
        color: #FFD03D;
        font-size: 20px;
        font-family: 'PT serif';
        /* margin-top: 33%; */
    }
    div.carousel-img-text p{
        font-size: 16px;
        font-family: 'Montserrat';
        margin-top: 5%;
    }
    .middle{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .news-img-padding{
        padding: 0% 16px 10%;
    }
    .news-grid{
        width: 93%;
        height: 50vh;
        margin-right: 5%;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
    }
    .news-img-plate{
        background-color: black;
        height: 29vh !important;
        width: 100% !important;
        border-radius: 30px;
        float: left;
        margin-left: 3%;
        box-shadow: 5px 5px 10px rgb(84, 109, 84);
        /* border: 1px solid red; */
    }
    div.news-name{
        padding: 30% 0% 0%;
        margin-top: 55% !important;
    }
    div.news-name h4{
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
        font-size: 20px;
    }
    div.news-name p{
        text-align: left;
        margin-left: 4%;
        font-size: 16px;
    }

    button.carousel-control-prev4, button.carousel-control-next4{
        background-color: transparent;
         border: none;
         height: 5vh;
         margin-top: -100%;
         padding: 0%;
         border-style: 1px solid red;
     }
     button.carousel-control-prev4 i.fa-arrow-left{
         color: #1F5234;
     }
     button.carousel-control-next4 i.fa-arrow-right{
         color: #1F5234;
     }
    /* ------------------------------------------------------ Home Mobile Responsive CSS End Here*/



































    /* ------------------------------------------------------ About Us Mobile Responsive */
    .mobile-hide{
        display: none;
    }
    .desktop-hide{
        display: block;
    }
    .row-padding{
        padding: 60px 16px 20px;
    }
    h5.about-title span{
        font-size: 25px;
        font-family: 'PT Serif';
        font-weight: bold;
        color: #1F5234;
    }
    .text-dash span:after {
        content: "";
        position: absolute;
        height: 5px;
        border-bottom: 4px solid #1F5234;
        top: 50%;
        width: 50%;
    }
    p.about-content{
        font-size: 16px;
        font-family: 'Montserrat';
        margin-top: 3%;
    }
    .mv-row-padding{
        padding: 30px 16px 20px;
    }
    .about-image {
        background-image: url("../images/about-img1.png");
        background-attachment: fixed;
        background-position: center; 
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;
    }
    .green-bg{
        background-color: green;
        height: 40vh;
        opacity: 40%;
    }
    .mv-title{
        font-size: 25px;
        font-family: 'PT Serif';
        font-weight: bold;
        color: #1F5234;
    }
    p.border-left{
        border-left: 6px solid #24AA5A;
        font-family: 'montserrat';
        color:#24AA5A;
        font-size: 16px;
        align-items: center;
        padding: 0% 3% 0%;
        margin-top: 2%;
    }
    p.text-paragrap{
        font-size: 16px;
        font-family: 'montserrat';
        color:#1F5234;
    }
    div.middle img{
        display: none;
    }
    h5.title{
        font-size: 25px;
        font-family: 'PT Serif';
        font-weight: bold;
        color: #1F5234;
        text-align: left !important;
    }
    .partner-padding{
        padding: 25px 16px 25px;
    }
    .partner-logo{
        width: 50%;
    }
    .partner-logo1{
        width: 25%;
    }
    div.col-md-4 img{
        padding: 0% 0% 3%;
    }
    div.col-md-6 img{
        padding: 0% 0% 3%;
    }
    .team-padding{
        padding: 30px 16px 0px;
    }
    .sub-title{
        font-size: 25px;
        font-family: 'montserrat';
        font-weight: normal;
        color: #1F5234;
    }
    .team-left-margin{
        margin-left: 0%;
    }
    .team-img-padding{
        padding: 10px 16px 20px ;
    }
    .our-team{
        width: 45%;
        height: 30vh;
        float: left;
        counter-increment: carousel-cell;
        color: black;
        /* border: 1px solid red; */
        margin-left: 3%;
    }
    div.team-img-plate{
        background-color: black;
        width: 100%;
        height: 18vh;
        border-radius: 180px;
        float: left;
        margin-left: 0%;
        box-shadow: 3px 3px 10px rgb(53, 77, 53);
    }

    button.carousel-control-prev, button.carousel-control-next{
       background-color: transparent;
        border: none;
        height: 5vh;
        margin-top: 65%;
        padding: 0%;
    }
    button.carousel-control-prev i.fa-arrow-left{
        color: #1F5234;
    }
    button.carousel-control-next i.fa-arrow-right{
        color: #1F5234;
    }
    div.team-name h4{
        font-size: 16px;
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
    }
    div.team-name p{
        font-size: 12px !important;
        text-align: left;
        margin-left: 4%;
        font-size: 15px;
    }
    .gallery-image1{
        background-image: url('../images/Gallery/image1.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 20vh !important;
        width: 54% !important;
        border-radius: 30px;
        float: left;
        margin-left: 3%;
        margin-top: 3%;
    }
    .gallery-image2{
        background-image: url('../images/Gallery/image2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 20vh !important;
        width: 39% !important;
        border-radius: 30px;
        float: left;
        margin-left: 3%;
        margin-top: 3%;
    }
    button.carousel-control-prev1, button.carousel-control-next1{
        background-color: transparent;
         border: none;
         height: 5vh;
         margin-top: 10%;
         padding: 0%;
        
     }
    button.carousel-control-prev1 i.fa-arrow-left{
        color: #1F5234;
        left: 0px;
    }
    button.carousel-control-next1 i.fa-arrow-right{
        color: #1F5234;
        margin-right: 0%;
    }
    /* ------------------------------------------------------ About Us Mobile Responsive */













    /* ------------------------------------------------------ Contact us Mobile Responsive */
    .cont-row-padding{
        padding: 50px 16px 16px;
    }
    div.col-right-padding div.card{
        border-radius: 30px;
    }
    .middle-form-details{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 7%;
    }
    h5.card-title{
        font-size: 25px;
        font-family: 'PT Serif';
        color: #1F5234;
    }
    h5.card-title span{
        font-size: 25px;
        font-family: 'PT Serif';
        color: #24AA5A;
    }
    h6.card-subtitle{
        font-size: 16px;
        font-family: 'montserrat';
        color: #1F5234;
    }
    div.card-body i.fas span{
        font-size: 15px;
        line-height: 20px;
        /* color: #1F5234 !important; */
    }
    .form-bg{
        background-color: #24AA5A;
        border-radius: 30px;
        text-align: left;
        padding: 5% !important;
    }
    h5.form-title{
        font-size: 25px;
        font-family: 'PT Serif';
        color: #ffffff; 
    }
    .form-text{
        font-size: 16px;
        font-family: 'montserrat';
        color: #ffffff;
    }
    .radius{
        border-radius: 30px;
        margin-top: 1%;
    }
    .map-padding{
        padding: 40px 16px 40px;
    }
    iframe{
        box-shadow: 5px 5px 10px rgb(192, 199, 192);
    }
    /* ------------------------------------------------------ Contact us Mobile Responsive Css End */














    /* Footer */
    .footer-submain-bg{
        background-color: #383838;
        color: white;
        text-align: justify;
        font-weight: normal;
        padding: 20px 25px 20px !important;
    }

    .footer-logo{
        width: 40%;
    }
    p.footer-content{
        font-size: 16px;
        font-family: 'Montserrat';
    }
    h1.footer-title{
        font-size: 25px;
        font-family: 'PT serif';
        padding-bottom: 0px;
    }
    div.col-md-9 .footer-sub-title{
        font-size: 20px;
        font-weight: bold;
        font-family: 'PT serif';
        color: #24AA5A;
    }
    .footer-highlight{
        color: #24AA5A;
        font-size: 20px;
    }
    .news-content{
        font-size: 12px;
        font-family: 'montserrat';
    }
    .service-content{
        font-size: 15px;
        font-weight: bold;
        font-family: 'PT serif';
        color:#24AA5A;
    }
    .footer-icon{
        color:#C4C4C4;
        margin-left: 5%;
        margin-top: 7%;
    }
    .footer-main-bg{
        background-color: #2C2B2B;
    }
    .footer-main-text{
        color: white;
        text-align: center;
        font-weight: bold;
        font-family: 'PT serif';
        font-size: 16px;
        padding: 5% 1% 1% 1%;
    }
}


/* (max-height: 667px) */
@media (min-width: 375px) and (max-width: 413px) and (max-height: 667px){
    
    .header-logo{
        width: 100% !important;
    }
    li.nav-item .nav-top{
        margin-top: 10px;
    }
    li.nav-item .main-nav-active{
        border-bottom: none !important;
        color: white !important;
        background-color: #24AA5A;
        padding:3% 5% 2%;
        border-radius: 30px;
    }
    p.carousel-txt1{
        font-size: 13px;
        font-family: 'Montserrat' !important;
        margin-top: -1%;
    }
    .carousel-txt2{
        font-size: 25px !important;
        font-family: 'Montserrat' !important;
        font-style: normal;
        font-weight: normal;
        margin-top: -8% !important;
    }
    p.carousel-txt3{
        font-size: 50px !important;
        font-family: 'PT Serif';
        margin-top: -13%;
    }
    button.carousel-btn{
        position: absolute;
        background-color: #24AA5A;
        color: #ffffff;
        font-size: 20px;
        font-family: 'PT Serif';
        padding: 2% 10% 2%;
        border-radius: 30px;
        border: none;
        margin-top: -5%;
    }
    


    




    div.team-img-plate{
        background-color: black;
        width: 100%;
        height: 22vh;
        border-radius: 180px;
        float: left;
        margin-left: 0%;
        box-shadow: 3px 3px 10px rgb(53, 77, 53);
    }
    p.services-bg-title{
        float: left;
        margin-top: 50%;
        margin-left: 5%;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title1{
        float: left;
        margin-top: 80%;
        margin-left: 5%;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    .carousel-img-text{
        border-radius: 30px;
        width: 90%;
        padding: 2%;
        position: absolute;
        right: 15%;
    }
    div.carousel-img-text h5{
        color: #FFD03D;
        font-size: 20px;
        font-family: 'PT serif';
    }
    .middle{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.news-name{
        padding: 30% 0% 0%;
        margin-top: 40% !important;
    }
    div.news-name h4{
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
        font-size: 20px;
    }
    div.news-name p{
        text-align: left;
        margin-left: 4%;
        font-size: 12px;
        list-style: 10%;
    }
    .parners-logo2{
        width: 90%;
        margin-top: -10% !important;
    }

    .mobile-title-top{
        margin-top: 15%;
    }
}

/* @media (min-width: 414px) and (max-height: 736px) */
@media (max-width: 414px) and (max-height: 736px) {
    .header-logo{
        width: 100% !important;
    }
    li.nav-item .nav-top{
        margin-top: 10px;
    }
    li.nav-item .main-nav-active{
        border-bottom: none !important;
        color: white !important;
        background-color: #24AA5A;
        padding:3% 5% 2%;
        border-radius: 30px;
    }
    li.nav-item .nav-top1{
        margin-top: 4%;
    }
    div.team-img-plate{
        background-color: black;
        width: 100%;
        height: 22vh;
        border-radius: 180px;
        float: left;
        margin-left: 0%;
        box-shadow: 3px 3px 10px rgb(53, 77, 53);
    }
    .parners-logo{
        width: 80%;
        margin-top: -5%;
    }
    .parners-logo2{
        width: 85%;
        margin-top: -7% !important;
    }
    .parners-logo2-a{
        width: 85%;
        margin-top: -11%;
    }
    .parners-logo3{
        width: 80%;
        margin-top: -11%;
    }
    .parners-logo4{
        width: 32%;
        margin-top: -6%;
    }
    .parners-logo5{
        width: 43%;
        margin-top: -6%;
    }
    p.services-bg-title{
        float: left;
        margin-top: 50%;
        margin-left: 5%;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    p.services-bg-title1{
        float: left;
        margin-top: 80%;
        margin-left: 5%;
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: 'PT serif';
    }
    
    .carousel-img-text{
        border-radius: 30px;
        width: 90%;
        padding: 2%;
        position: absolute;
        right: 15%;
    }
    div.carousel-img-text h5{
        color: #FFD03D;
        font-size: 20px;
        font-family: 'PT serif';
    }
    .middle{
        display: flex;
        justify-content: center;
        align-items: center;
    }



    div.news-name{
        padding: 30% 0% 0%;
        margin-top: 40% !important;
    }
    div.news-name h4{
        text-align: left;
        font-family: 'PT serif';
        color: #1F5234;
        margin-left: 4%;
        font-size: 19px;
    }
    div.news-name p{
        text-align: left;
        margin-left: 4%;
        font-size: 14px;
        list-style: 10%;
    }
    .mobile-title-top{
        margin-top: 15%;
    }
}

/* ---------------------------------------------------------------------------------------------------------------- */