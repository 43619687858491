@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=PT+Serif:wght@700&family=Roboto:wght@900&display=swap');
*{
    font-family: 'Montserrat', sans-serif;
    font-family: 'PT Serif', serif;
    font-family: 'Roboto', sans-serif;
}
.body{
    color: #1F5234;
}
.radius{
    border-radius: 30px;
}
.top{
    margin-top: 7%;
}
.row-padding{
    padding: 3% 10% 5%;
}
.cont-row-padding{
    padding: 8% 10% 9% 5%;
}
/* -------------------------------------------------------------------- */

/* contact details*/
.middle-form-details{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card{
    border: none;
    text-align: left;
    /* padding: 10%; */
}
h5.card-title{
    font-size: 40px;
    font-family: 'PT Serif';
    color: #1F5234;
}
h5.card-title span{
    font-size: 40px;
    font-family: 'PT Serif';
    color: #24AA5A;
}
h6.card-subtitle{
    font-size: 20px;
    font-family: 'montserrat';
    color: #1F5234;
}
div.card-body i.fas{
    color: #1F5234 !important;
}
/* Contact Details Css End Here --------------------------------------------------------------------------- */

/* Contact form */
.form-bg{
    background-color: #24AA5A;
    border-radius: 30px;
    text-align: left;
}
h5.form-title{
    font-size: 40px;
    font-family: 'PT Serif';
    color: #ffffff; 
}
.form-text{
    font-size: 25px;
    font-family: 'montserrat';
    color: #ffffff;
}
.form-btn{
    background-color: #FFD03D;
    font-size: 25px;
    font-family: 'PT serif';
    color: #ffffff;
    margin-top: 4%;
    padding: 2%;
}
button.form-btn:hover{
    background-color: #06ee63;
    color: #ffffff;
}
/* Conatct Form CSS End Here -------------------------------------------------------------- */

/* Location Map */
.iframe-size{
    width: 100%;
    height: 70vh;
}
.map-padding{
    padding: 4% 10% 10%;
}
/* Location Map CSS End Here -------------------------------------------------------------- */